.sider-logo {
  padding: 15px;
  height: 100px;
  text-align: center;
}

.sider-logo > img {
  height: 60px;
}

.sider-parent {
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: space-between;
}

.react-trello-card span {
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.react-trello-board {
  height: 80vh !important;
}

.sider-group .ant-menu-item-group-title {
  margin: 0;
  padding: 0;
}

.messages-icon {
  cursor: pointer;
  position: fixed;
  top: 50%;
  right: 0%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  z-index: 1;
  background: #fff;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}